
import 'intersection-observer';
import { uaCheck } from "./modules/utils";

uaCheck();

// ----------------
// アニメーション付与
// ----------------
const option = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
};
const callBack = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("is-show");
        }
    });
};
const observer = new IntersectionObserver(callBack, option);
const targets = document.querySelectorAll(".ani-fadeIn");
targets.forEach((target) => {
    observer.observe(target);
});
